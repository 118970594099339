

.App {
  height: auto;
}

.app-container {
  display: flex;
  flex-direction:column;
  min-height: 100vh;
  position: relative;
}

.footer {
  margin-top: auto;
  display: flex;
  width: -webkit-fill-available;
  height: 3.875rem;
  padding: 1.25rem 5rem;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  flex-shrink: 0;
  background: var(--gray-scale-black, #000);
}

