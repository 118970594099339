.header {
    background-color: #000000;
    display: flex;
    flex-direction: row;
    height: 6.25rem;
    flex-shrink: 0;
    padding: 1rem 1.12rem;
}

.header-button-container {
    margin-left: 18.18rem;
    display: inline-flex;
    align-items: center;
    gap: 1.5rem;
}

.header-editonly-container {
    margin-left: 24rem;
    margin-right: 10rem;
    display: inline-flex;
    align-items: center;
}

.header-text {
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 137.5% */
}

.header-flex {
    justify-content: center;
    align-items: center;
}

.header-logo{
    margin-top: 0.56rem;
    width: 10.1875rem;
    height: 3.0625rem;
    flex-shrink: 0;
    background: url('../Assets/logo.png'), lightgray 0px -0.04px / 112.27% 100.164% no-repeat;   
}

.header-logo-text {
    margin-top: 1.44rem;
    margin-left: 0.68rem;
    color: var(--gray-scale-white, #FFF);
}

.header-edit {
    display: flex;
    padding: 0.75rem 1.5rem;
}

.header-edit-only { 
    display: flex;
    padding: 12px 24px;
}

.header-approve {
    display: flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    gap: 1.5rem;
}

.header-button-unselected {
    background-color: inherit;
    border: 1px solid var(--gray-scale-white, #FFF);
    color: var(--gray-scale-white, #FFF);
}

.header-button-selected, .header-approve:focus, .header-edit:focus {
    background: var(--primary-primary-400, #0336FF);
    border: none;
    color: var(--gray-scale-white, #FFF);
}

.header-user-container {
    margin-left: 18.18rem;
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

.header-user {
    margin-top: 0.68rem;
    background-color: inherit;
    border: 1px solid var(--gray-scale-white, #FFF);
    display: inline-flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    gap: 0.375rem;
    flex-shrink: 0;
    color: var(--gray-scale-white, #FFF);
    width: 12rem;
}

.header-user-logout {
    margin-top: 0rem;
    background: var(--gray-scale-black, #000);
    color: var(--gray-scale-white, #FFF);
    display: inline-flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    flex-shrink: 0;
}

@media only screen and (min-width: 1600px) {
    .header-button-container {
        margin-left: 34.18rem;
    }
}