body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

html::-webkit-scrollbar-thumb {
  background: #ddd8d8f5;
  border-radius: 8px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.767),inset -2px -2px 2px rgba(0,0,0,.25);
}

html::-webkit-scrollbar-track {
  background: #f0eded;
  border-radius:2rem ;
}