.count-overlay-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 79.25rem;
}

.video-overlay-loading {
    display: flex;
    align-items: center;
    width: 79.5rem;
    height: inherit;
}

.count-overlay-table-row {
    padding: 0.5rem;
    width: 79.25rem;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    border-bottom: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
}

.count-overlay-table-header {
    z-index: 9;
    position: -webkit-sticky;
    position: sticky;
    top: 4.75rem;
    height: 3.5rem;
    background: var(--gray-scale-neutral-2, #EAEBEC);
    align-items: center;
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.header-icon {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.count-overlay-table-body {
    width: 79.25rem;
    min-height: 9.625rem;
    flex-shrink: 0;
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.count-overlay-table-col {
    min-width: 6.37rem;
    max-width: 26rem;
}

.count-overlay-title-flex {
    flex-direction: column;
    align-items: flex-start;
}

.count-overlay-title{
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.count-overlay-table-col:nth-child(1) {
    display: flex;
    width: 25.8125rem;
    gap: 0.5rem;
}

.count-overlay-table-col:nth-child(2) {
    width: 6.375rem;
}

.count-overlay-table-col:nth-child(3) {
    width: 20.25rem;
    flex-shrink: 0;
}

.count-overlay-table-col:nth-child(4) {
    width: 8.125rem;
    flex-shrink: 0;
}

.count-overlay-table-col:nth-child(5) {
    width: 8.125rem;
    flex-shrink: 0;
}

.video-long-desc-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
}

.video-details-value-txt {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.video-long-desc {
    width: 23.8125rem;
    height: 3.875rem;
}

.video-long-desc-show-more {
    width: 23.8125rem;
}

.show-more-flex {
    padding: 0rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    opacity: 0.800000011920929;
    background: inherit;
    border: none;
}

.video-overlay {
    z-index: 9;
    position: absolute;
    height: inherit;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.425);
}

.video-table-flex {
    margin-top: 12rem;
    margin-left: 3rem;
    display: inline-flex;
    height: 33.8125rem;
    padding: 0rem 2rem 2rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 1.5rem; */
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
    overflow: auto;
}

.video-table-flex-heading {
    padding-top: 2rem;
    background: inherit;
    padding-bottom: 1rem;
    z-index: 9;
    position: sticky;
    top: 0rem;
    display: flex;
    width: 79.25rem;
    justify-content: space-between;
    align-items: flex-start;
    position: -webkit-sticky;
}

.video-table-heading-txt {
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 125% */
    letter-spacing: -0.015rem;
    max-width: 70rem;
}
.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}


@media only screen and (min-width: 1600px) {
    .video-table-flex {
        margin-left: 17rem;
    }
}
