

.select {
    display: flex;
    margin-bottom: 0rem;
    width: 11.9375rem;
    padding: 1rem 0rem 1rem 1rem;
    align-items: center;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    border: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-neutral-2, #EAEBEC);
}

.width-section-input {
    width:30rem!important;
}

.custom-arrow-kv {
    display: inline;
    pointer-events: none;
    top: 0rem;
    right: 1rem;
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;
    background: url("../Assets/chevron-down.png");
}


.select-value {
    margin-bottom: 0rem;
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    flex: 1 0 0;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    border-top: 1px solid var(--gray-scale-gray, #B2B7BD);
    border-right: 1px solid var(--gray-scale-gray, #B2B7BD);
    border-bottom: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-white, #FFF);
}



.primary-section-select {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.key-select-input {
    width: 9rem;
}
.select-input {
    
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.value-select-input {
    width: inherit;
}

.key-options {
    position: absolute;
    margin-top: 3.5rem;
    width: 10rem;
}

.value-options {
    position: absolute;
    margin-left: 12rem !important; 
    width: 36.5rem !important;
    margin-top: 3.5rem;
}