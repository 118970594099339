.import-metadata-container {
    display: inline-flex;
    max-height: -webkit-fill-available;
    min-height: 16.6875rem;
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex-shrink: 0;
    background: #FFF;
    top: 10rem;
    left: 5.37rem;
    position: absolute;
    overflow: auto;
}

.import-metadata-container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
.import-metadata-container::-webkit-scrollbar-thumb {
    background: #eeebebf5;
    border-radius: 8px;
}
  
.import-metadata-container::-webkit-scrollbar-track {   
    background: #f8f8f8;
    border-radius:2rem ;
}

.import-metadata-container::-webkit-scrollbar-track:end {
    background: transparent;
    margin-bottom: 10px; 
}

.import-metadata-container::-webkit-scrollbar-track:start {
    background: transparent;
    margin-top: 10px;
}

.importMetadataHeader {
    display: flex;
    width: 73rem;
    justify-content: space-between;
    align-items: flex-start;
}

.importMetadataHeaderText {
    color: var(--gray-scale-black, #000);

    /* Headings/H3 */
    font-family: Helvetica Neue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 125% */
    letter-spacing: -0.015rem;
}

.importMetadataBody {
    display: flex;
    align-items: flex-end;
    gap: 0.625rem;
}

.importMetadataBodyContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
}

.importMetadataBodyText {
    color: var(--gray-scale-black, #000);

    /* Body/Large */
    font-family: Helvetica Neue;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 133.333% */
}

@media only screen and (min-width: 1600px) {
    .import-metadata-container {
        left:7rem;
    }
    .importMetadataHeader {
        width: 82.25rem;
    }
}