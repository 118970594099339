.approve-keyword {
    margin-left: 7.5rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.approve-keyword-table-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
}

.approve-keyword-heading {
    color: #191919;
    text-align: right;
    
    /* Headings/H2 */
    font-family: Helvetica Neue;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.375rem; /* 126.667% */
    letter-spacing: -0.0375rem;
}

.approve-keyword-count-flex {
    display: flex;
    width: 47.1875rem;
    justify-content: space-between;
    align-items: center;
}

.approve-keyword-count {
    color: #191919;
    text-align: right;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.bulk-action-txt {
    display: flex;
    gap: 0.5rem;
    border: none;
    background: inherit;
    color: var(--primary-500, #0228BF);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.bulk-action-flex {
    display: flex;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    opacity: 0.800000011920929;
}

.heading-flex {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.heading-txt {
    color: #191919;
    text-align: right;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.header-row {
    display: flex;
    height: 3rem;
    flex-shrink: 0;
    background: var(--gray-scale-neutral-2, #EAEBEC);
}

.approve-keyword-table {
    width: 47.1875rem;
}

.heading-txt-flex {
    display: inline-flex;
    padding-left: 1px;
    align-items: center;
    gap: 1.25rem;
}

.keyword-name-heading {
    margin-left: 1rem;
    margin-right: 8rem;
}

.video-heading {
    margin-left: 5rem;
}

.action-heading {
    margin-left: 8.5rem;
}

.table-row {
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-right: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    border-bottom: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    border-left: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
}

.approve-keyword-pagination {
    display: flex;
    width: 47.25rem;
    padding-top: 16px;
    padding-bottom: 16px;
    align-items: flex-end;
    padding-left: 36rem;
}

.keyword-search-box {
    margin-top: 8.87rem;
    margin-left: 2.31rem;
    width: 25.5rem;
    height: 33rem;
    flex-shrink: 0;
    background: var(--gray-scale-neutral-2, #EAEBEC);
}

.approve-keyword-container {
    display: flex;
    flex-direction: row;
}

.keyword-search-flex {
    margin-top: 0.75rem;
    margin-left: 1.25rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.125rem;
}

.keyword-input-container { 
    display: flex;
    width: 20.4375rem;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background: var(--gray-scale-white, #FFF);
}

.keyword-input-txt {
    width: inherit;
    border: none;
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

input[type="text"]:focus {
    outline: none !important;
    border: none;
}

.approved-success-div{
    position: absolute;
    display: flex;
    width: 75rem;
    padding: 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    border-radius: 0.5rem;
    background: var(--semantic-100, #A4D487);
}

.approved-success {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
}

.body-base-black {
    color: var(--gray-scale-black, #000);
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.body-base-bold-black {
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem;
}

.bulk-action-container {
    position: absolute;
    margin-top: 1rem;
    display: flex;
    width: 12.9375rem;
    padding: 0.25rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background: var(--gray-scale-white, #FFF);
    box-shadow: 0px 6px 12px 0px rgba(39, 43, 48, 0.05);
}

.bulk-approve-inner {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: flex-start;
    align-self: stretch;
}

.bulk-reject-container {
    background: var(--semantic-100, #e7a3a3);
}

.seperator {
    height: 0.0625rem;
    align-self: stretch;
    background: var(--gray-scale-neutral-3, #E0E1E2);
}

.approve-keyword-search-result {
    display: flex;
    width: 20.5rem;
    max-height: 9.8125rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background: var(--gray-scale-white, #FFF);
    box-shadow: 0px 6px 12px 0px rgba(39, 43, 48, 0.05);
    overflow-y: auto;
}

.approve-keyword-search-result::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
.approve-keyword-search-result::-webkit-scrollbar-thumb {
    background: #eeebebf5;
    border-radius: 8px;
}
  
.approve-keyword-search-result::-webkit-scrollbar-track {   
    background: #f8f8f8;
    border-radius:2rem ;
}

.approve-keyword-search-result::-webkit-scrollbar-track:end {
    background: transparent;
    margin-bottom: 10px; 
}

.approve-keyword-search-result::-webkit-scrollbar-track:start {
    background: transparent;
    margin-top: 10px;
}

.approve-keyword-each-search {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: flex-start;
    align-self: stretch;
}

.body-small {
    flex: 1 0 0;
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}


.gray-background {
    background: var(--gray-scale-neutral-2, #EAEBEC);
}

@media only screen and (min-width: 1600px) {
    .approve-keyword-count-flex, .approve-keyword-table {
        width: 57.1875rem;
    }
    .keyword-search-box {
        width: 30.5rem;
    }    
    .keyword-input-container {
        width: 27.4375rem;
    }
}