.radio-container {
    display: flex;
    width: 71rem;
    justify-content: space-between;
    align-items: flex-start;
}
.mandatory {
    color: var(--semantic-error-200, #C90000);
    /* Body/Base */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
}

input[type="radio"] {
    -ms-transform: scale(1.84); /* IE 9 */
    -webkit-transform: scale(1.84); /* Chrome, Safari, Opera */
    transform: scale(1.84);
}

.field-description {
    display: flex;
    padding-top: 30px;
    align-items: flex-start;
    gap: 0.625rem;
    width: 16rem;

}

.field-description-txt {

    margin-bottom: 0;
    color: #000;

    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
    
}
.radio-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.radio-options {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    align-self: stretch;
}

.radio-label {
    margin-bottom: 0rem;
    color: var(--gray-scale-black, #000);

    /* Body/Base */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.radio-note-txt {
    color: var(--gray-scale-neutral-8, #404446);

    /* Body/Base */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}