.video-details-card {
    scroll-margin-top: 4rem;
    margin-top: 2rem;
    display: inline-flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.4375rem;
    border-top: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
}

.video-details-card-hr {
    display: flex;
    width: 73rem;
    align-items: center;
    gap: 20.375rem;
    color: #191919;
    text-align: right;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.video-details-card-body {
    display: flex;
    width: 72.9375rem;
    align-items: flex-start;
    gap: 1.5rem;
    min-height: 26rem;
}

.video-details-thumbanail {
    margin-top: 8rem;
}

.video-details-outer-container  {
    display: flex;
    width: 54.375rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex-shrink: 0;
}

.video-details-inner-container {
    display: flex;
    width: 54.375rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.video-details-key {
    text-align: left;
    width: 9.375rem;
    color: #000;

    /* Body/Small Bold */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.video-details-row {
    display: flex;
    align-items: flex-start;
    gap: 3.5rem !important;
}

.video-details-description {
    width: 29.9375rem;
    max-height: 2.5rem;
    overflow-wrap: break-word;  
    word-wrap: break-word;
}

.video-details-value {
    text-align: left;
    color: #000;
    
    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.video-details-icon {
    margin-right: 0.5rem;
}

.video-details-view-metadata-button {
    border: none;
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    background: var(--primary-primary-400, #0336FF);
    color: var(--gray-scale-white, #FFF);
    text-align: center;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.video-details-session-locked-card {
    display: flex;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    background: var(--primary-100, #BFCCFF);
}

.video-details-session-locked-card-hr {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.video-details-session-locked-card-hr-text {
    color: #000;

    /* Body/Small Bold */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
    width: 13.75rem;
}

.video-details-session-locked-card-body {
    color: var(--gray-scale-black, #000);

    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
    width: 50.9375rem;
    text-align: left;
}

.video-details-button{
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;  
}

.video-details-copy-button {
    background-color: inherit;
    display: flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border: 1px solid var(--primary-500, #0228BF);
    color: var(--primary-500, #0228BF);
    text-align: center;
    
    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.video-details-copy-button-flex{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.video-details-deactivate-button {
    background: inherit;
    display: flex;
    width: 5.8125rem;
    height: 1.9375rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    color: var(--primary-500, #0228BF);
    text-align: center;
    border: 1px solid var(--primary-500, #0228BF);
    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.disable {
    color: var(--gray-scale-neutral-3, #E0E1E2) !important;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2) !important;
}

.toolKit-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    margin-left: 21.25rem;
    margin-top: 20.55rem;
}
.toolkit {
    display: flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.0625rem;
    opacity: 0.949999988079071;
    background: var(--tint-black, #01050E);
    color: #FFF;
    font-family: Public Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */
}

@media only screen and (min-width: 1600px) { 
    .video-details-card {
        width: 83rem;
    }
}