.editKeyword-Overlay {
    z-index: 9;
    position: absolute;
    height: inherit;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.425);
}

.editKeyword-card {
    width: 40rem;
    margin-top: 12rem;
    margin-left: 25rem;
    display: inline-flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 4.375rem;
    background: var(--gray-scale-white, #FFF);
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
}

.editkeyword-card-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-gap-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.flex-gap-half {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5625rem;
}

.horizontal-flex {
    display: flex;
    align-items: flex-start;
}

.editKeyword-card-heading {
    width: 35rem;
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 125% */
    letter-spacing: -0.015rem;
}

.body-large {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.body-small-bold-gray {
    color: var(--gray-scale-neutral-6, #81888C);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.editkeyword-input-container {
    display: flex;
    width: 34.25rem;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background: var(--gray-scale-white, #FFF);
}

input[type="text"] {
    border: none;
}

.editKeyword-button {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
}

.editKeyword-cancel-button {
    display: flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border: 1px solid var(--primary-500, #0228BF);
    color: var(--primary-500, #0228BF);
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
    background-color: inherit;
}

.editKeyword-confirm-button {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    background: var(--gray-scale-neutral-3, #E0E1E2);
    color: var(--gray-scale-white, #FFF);
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
    border: none;
}

.active-confirm-button {
    background: var(--primary-primary-400, #0336FF)!important;
}

.editkeyword-searchinput {
    width: inherit;
}


.editkeyword-searchresult {
    display: flex;
    width: 34.25rem;
    height: 15.75rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background: var(--gray-scale-white, #FFF);
    box-shadow: 0px 6px 12px 0px rgba(39, 43, 48, 0.05);
    overflow-y: auto;
}

.editKeyword-each-searchresult {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: flex-start;
    align-self: stretch;
}

.editKeyword-each-searchresult-divider {
    height: 0.0625rem;
    flex-shrink: 0;
    align-self: stretch;
    background: var(--gray-scale-neutral-3, #E0E1E2);
}

.body-small-bold-black {
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.editkeyword-outer-divider {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
}