.idle-timeout-container {
    z-index: 999!important;
    position: absolute;
    height: inherit;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.425);
}

.idle-timeout-popup {
    width: 18rem;
    top:16rem;
    left:35rem;
    position: relative;
    display: inline-flex;
    height: 14.8125rem;
    padding: 2.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 1.8125rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background: #FFF;
    /* Small */
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.16);
}

.idle-timeout-popup-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
}

.idle-timeout-popup-heading {
    color: #000;
    text-align: right;

    /* Body/Large */
    font-family: Helvetica Neue;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 133.333% */
}

.idle-timeout-popup-desc {
    width: 14.4375rem;
    height: 5.0625rem;
    color: #000;

    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.idle-timeout-popup-button {
    border: none;
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    background: var(--primary-primary-400, #0336FF);
    color: var(--gray-scale-white, #FFF);
    text-align: center;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}