.keyword-name {
    width: 12.5rem;
    color: #191919;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
    width: 12.5rem;
    word-wrap: break-word;
}

.keyword-outer-flex {
    display: flex;
    align-items: center;
    gap: 8.375rem;
}

.keyword-name-inner-flex {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.videos-no-flex {
    display: flex;
    flex-direction: row;
}

.videos-no-icon {
    background: inherit;
    border: none;
}

.videos-no {
    text-align: left;
    width: 6.25rem;
    height: 1.375rem;
    overflow: hidden;
    color: #191919;
    text-overflow: ellipsis;
    whitespace: nowrap;
    text-wrap: nowrap;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
    background: inherit;
    border: none;
    align-self: center;
}

.action-flex {
    display: flex;
    align-items: flex-start;
    gap: 1.875rem;
}

.bulk-approve-inner:hover {
    background: var(--gray-scale-neutral-2, #EAEBEC);
}

.status-keyword {
    width: 8rem;
    color: #191919;
    text-overflow: ellipsis;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 530;
    line-height: 1.375rem; /* 137.5% */
}

.status-icon {
    vertical-align: top;
}

@media only screen and (min-width: 1600px) {
    .approve-keyword-count-flex, .approve-keyword-table {
        width: 60.1875rem;
    }
    .action-heading {
        margin-left: 21rem;
    }
    .videos-no{
        width: 7.25rem;
    }
}