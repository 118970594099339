.card-input-flex {
    display: flex;
    flex-direction: row;
    gap: 0.62rem;
}
    
.card-input {
    /* margin-top: 0.63rem; */
    display: flex;
    width: 44.3125rem;
    height: 3.5rem;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background: var(--gray-scale-white, #FFF);

}

.card-input-active {
    border: 1px solid var(--primary-primary-400, #0336FF)!important;
    caret-color: var(--primary-primary-400, #0336FF)!important
}

.input-class {
    border: none;
    width: 100%;
}

.input-class::placeholder, .input-class::-webkit-input-placeholder , .input-class::-moz-placeholder {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.input-class[type="text"]:focus {
    outline: none !important;
    border: none;
}
    

.card-submit {
    display: flex;
    height: 3.5rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    margin-left: 0.63rem;
    background: var(--gray-scale-neutral-3, #E0E1E2);
    color: var(--gray-scale-neutral-5, #A1A6A9);
    text-align: center;
    border: none;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.card-submit-selected {
    background: var(--primary-primary-400, #0336FF);
    color: var(--gray-scale-white, #FFF);
}


