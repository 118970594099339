.edit-card-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.assign-section-card {
    display: flex;
    width: 75rem;
    scroll-margin-top: 17rem;
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5625rem;
    background: #FFF;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
}

.assign-section-card-hr {
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 125% */
    letter-spacing: -0.015rem;
}

.assign-section-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.primary-section {
    display: flex;
    width: 74rem;
    align-items: flex-start;
    gap: 6.25rem;
    margin-bottom: 2rem;
}

.primary-section-input {
    display: flex;
    width: 48.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex-shrink: 0;
}

.primary-section-input-tx {
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.mandatory {
    color: var(--semantic-error-200, #C90000);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
}

.assign-section-key {
    display: flex;
    width: 11.9375rem;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
}

.edit-card-key-dropdown {
    margin-right: 1rem;
    display: flex;
    width: 11.9375rem;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    border: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-neutral-2, #EAEBEC) url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'> <path d='M14.25 6.75L9 12L3.75 6.75' stroke='#404446' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/></svg>") no-repeat;
    background-position: right 1rem;
}

.edit-card-key-dropdown select {
    margin-right: 1rem;
}

.field-description {
    display: flex;
    padding-top: 30px;
    align-items: flex-start;
    gap: 0.625rem;
    width: 16rem;

}

.field-description-txt {
    margin-bottom: 0;
    color: #000;
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.sec-add-section {
    position: absolute;
    margin-top: 6rem;
    margin-left: 38rem;
    background-color: inherit;
    border: none;
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.375rem;
}

.uploading {
    left: 7.5rem !important;
}

.thumb-uploading {
    left: 0.55rem !important;
}

.add-section-txt {
    color: var(--primary-500, #0228BF);

    /* Body/Small Bold */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.general-video-details-card {
    display: flex;
    width: 75rem;
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    background: #FFF;
    scroll-margin-top: 17rem;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
}

.general-video-item-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.5rem;
}



.desc-textarea {
    width: 100%;
    display: flex;
    height: 7.9375rem;
    padding: 1rem;
    align-items: flex-start;
    gap: 0.75rem;
    flex: 1 0 0;
    color: var(--gray-scale-black, #000);
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-white, #FFF);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.desc-textarea-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}

.Edit-poster-thumbnail-card {
    display: flex;
    width: 75rem;
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    background: #FFF;
    scroll-margin-top: 17rem;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
}

.edit-poster-card-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.edit-poster-card-para {
    width: 48rem;
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.edit-poster-card-body {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.edit-poster-innercontainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.edit-poster-innerheading {
    color: #191919;
    text-align: right;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.edit-poster-image {
    width: 17.0625rem;
    height: 26rem;
}

.edit-thumbnail-image {
    width: 9.375rem;
    height: 14.28569rem;
}
.edit-image-button {
    border :none;
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    position: relative;
    background: var(--primary-primary-400, #0336FF);
    color: var(--gray-scale-white, #FFF);
    text-align: center;
    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}
.edit-poster-image-button {
    left: 11rem;
    bottom: 5rem;
}
.edit-thumbnail-image-button {
    left: 3.5rem;
    bottom: 5rem;
}

.Add-metadata-card {
    display: flex;
    width: 75rem;
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    background: #FFF;
    scroll-margin-top: 17rem;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
}

.publishing-settings-card {
    background: #FFF;
    /* Medium */
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
    display: flex;
    width: 75rem;
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.action-card{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2rem ;
    gap: 57.8125rem;
}

.action-card-cancel {
    display: flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    background-color: inherit;
    color: var(--primary-500, #0228BF);
    text-align: center;
    border: 1px solid var(--primary-500, #0228BF);
    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.action-card-submit {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    background: var(--primary-primary-400, #0336FF);
    color: var(--gray-scale-white, #FFF);
    text-align: center;
    border: none;
    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.delete-icon { 
    position: absolute;
    margin-left: 51rem;
    margin-top: 2.5rem;
}

.start_date_Text {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.start-date {
    width: 23rem;
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-white, #FFF);
}

.create-new-option {
    margin-left: 0.5rem;
}

.start_date_flex {
    width: 49rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.required-text  {
    scroll-margin-top: 8rem;
    color: var(--gray-scale-black, #914343);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
}



@media only screen and (min-width: 1600px) {
    .assign-section-card,.general-video-details-card {
        width: 85rem;
    }
    .primary-section, .single-dropdown-container, .input-container {
        width: 84rem !important;
        gap: 12.25rem !important;
    }
    .radio-container {
        width: 76.75rem !important;
    }
    .Edit-poster-thumbnail-card, .Add-metadata-card , .publishing-settings-card {
        width: 85rem !important;
    }
    .action-card {
        gap: 67.8125rem;
    }
}