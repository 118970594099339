.edit-metadata {
  margin-left: 7.5rem;
  display: flex;
  flex-direction:column;
  gap: 2rem;
}

.no-wrap {
  white-space: nowrap;
}

.timeOut-container {
  margin-top: 3rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  background: var(--gray-scale-neutral-2, #EAEBEC);
  width: 70rem;
  padding: 1rem;
  border-radius: 0.4rem;
}

.timeOut-heading-flex {
  display: flex;
  align-items: flex-start;
  gap: 61rem;
}
.description-card {
  margin-top: 3rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  background: #FFF;
}

.title-text {
  color: var(--gray-scale-black, #000);
  text-align: right;
  font-family: Helvetica Neue;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.75rem; /* 115.789% */
  letter-spacing: -0.0475rem;
}

.description-text {
  display: flex;
  flex-direction: column;
  color: var(--gray-scale-neutral-8, #404446);
  font-family: Helvetica Neue;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 133.333% */
  width: 58rem;
}

.card {
  display: flex;
  width: 75rem;
  padding: 1rem 1rem 2rem 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5625rem;
  background: #FFF;

}
  
.header-card {
  color: var(--gray-scale-black, #000);

  /* Headings/H3 */
  font-family: "Helvetica Neue";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.24px;
  background: inherit;
  text-align: left;
  
} 

.body-card {
  display: flex;
  flex-direction: column;
  /* align-items: flex-end; */
  gap: 0.625rem;
  width: 51.3rem;
  height: 5.63rem;
}

.card-text {
  text-align: left;
  color: var(--gray-scale-black, #000);

  /* Body/Large */
  font-family: Helvetica Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  left: 0rem;
} 

@media only screen and (min-width: 1600px) {
  .card {
    width: 85rem;
  }
}
