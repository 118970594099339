.view-metadata {
    display: flex;
    flex-direction:column;
    margin-left: 7.5rem;  
}

.view-metadata-desc-card {
    margin-top: 5.25rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
}


.view-metadata-text {
    font-family: Helvetica Neue;
    font-style: normal;
    text-align: left;
}

.view-metadata-heading {
    color: var(--gray-scale-black, #000);
    font-size: 2.375rem;
    font-weight: 500;
    line-height: 2.75rem; /* 115.789% */
    letter-spacing: -0.0475rem;
}

.view-metadata-desc {
    color: var(--gray-scale-neutral-8, #404446);
    gap: 1.5rem;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem; /* 133.333% */
}

.h3-title {
    color: var(--gray-scale-black, #000);
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.875rem; /* 125% */
    letter-spacing: -0.015rem;
}

.view-metadata-desc-box {
    width: 29.9375rem!important;
    max-height: 2.5rem;
}

.video-info-row {
    display: flex;
    align-items: flex-start;
    gap: 3.5rem;
}

.poster-thumbnail-column {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
}

.poster-thumbnail-text {
    color: #191919;
    text-align: left;
    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.poster {
    width: 17.0625rem;
    height: 26rem;
    /* background: url(''), lightgray 50% / contain no-repeat; */
}

.thumbnail {
    width: 9.375rem;
    height: 14.28569rem;
    /* background: url(''), lightgray 50% / contain no-repeat; */
}

.video-info-key {
    width: 9.375rem;
    text-align: left;
    color: #000;

    /* Body/Small Bold */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.video-info-value {
    color: #000;
    width: 50rem;
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.video-info-thumbnail {
    position: absolute;
    right: 1.38rem;
    top: 0.125rem;
    width: 17.0625rem;
    height: 14.625rem;
    /* background: url(''), lightgray 6.516px -77px / 97.424% 177.778% no-repeat; */
}

.card-common {
    margin-top: 2rem;
    background: #FFF;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.12);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 1rem 1rem 2rem 1rem;
    gap: 1.5625rem;
    width: 75rem;
}

.general-video-details {
    display: inline-flex;
}
 
.poster-thumbnail {
    display: inline-flex;
}

.metadata {
    display: inline-flex;
}

.publishing-settings {
    display: inline-flex;
    width: 75rem;
}

.card-body-common {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    position: relative;
    gap: 1.5rem;
}

.video-title-info-body {
    gap: 1rem;
}

.back-button {
    background-color: inherit;
    margin: 2.88rem 0rem;
    width: 5.5rem;
    display: flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    border: 1px solid var(--primary-500, #0228BF);
    color: var(--primary-500, #0228BF);
    text-align: center;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}


.review-metadata-edit-button {
    margin-left: 66.5rem;
    background-color: inherit;
    margin-top: 0.5rem;
    display: flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 0.375rem;
    border: 1px solid var(--primary-500, #0228BF);
    color: var(--primary-500, #0228BF);
    text-align: center;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.button-div {
    display: flex;
    width: 75rem;
    justify-content: space-between;
    align-items: flex-start;
}

.save-button-div {
    margin: 2.88rem 0rem;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
}

.save-button {
    display: flex;
    height: 2.875rem;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border: 1px solid var(--primary-500, #0228BF);
    background-color: inherit;
    color: var(--primary-500, #0228BF);
    text-align: center;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.save-activate-button {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    background: var(--primary-primary-400, #0336FF);
    border: none;
    color: var(--gray-scale-white, #FFF);
    text-align: center;

    /* Body/Base Bold */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

@media only screen and (min-width: 1600px) {
    .card-common , .button-div{
        width: 85rem;
        /* gap: 1.75rem; */
    }
    .review-metadata-edit-button {
        margin-left: 76.5rem;
    }
    
}