.app-overlay {
    top: 0rem;
    bottom: 0rem;
    right: 0rem;
    left: 0rem;
    z-index: 9;
    position:absolute;
    height: inherit;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.70);
}