.search-result-table {
    display: flex;
    width: 73rem;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.search-result-table-row {
    padding: 0.5rem;
    width: 72.93919rem;
    height: 4.25rem;
    flex-shrink: 0;
    text-align: left;
    align-items: flex-start;
    border-bottom: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    color: #191919;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.search-result-header {
    background: var(--gray-scale-neutral-2, #EAEBEC);
    display: flex;
    flex-direction: row;
    gap: 1rem;
    color: #191919;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.search-result-body {
    display: flex;
    padding-top: 16px;
    align-items: flex-start;
    gap: 1rem;
}

.table-header-img {
    display: flex;
    gap:0.5rem
}

.seo-owner-div {
    width: 3.2rem;
}

.video-title-icon {
    margin-top: 0.25rem;
    width: 1rem;
    height: 1rem;
}

.table-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.search-result-table-col {
    min-width: 3.75rem;;
    max-width: 17rem;;
}

.search-result-table-col:nth-child(1) {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
}

.search-result-table-col:nth-child(2) {
    width: 17rem;
    height: 3rem;
    flex-shrink: 0;
}

.search-result-table-col:nth-child(3) {
    width: 7rem;
    height: 3rem;
    flex-shrink: 0;
}

.search-result-table-col:nth-child(4), .search-result-table-col:nth-child(5) {
    width: 7.625rem;
    height: 3rem;
    flex-shrink: 0;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-result-table-col:nth-child(6) {
    width: 7.1875rem;
    height: 1.5rem;
    flex-shrink: 0;
}

.search-result-table-col:nth-child(7) {
    width: 8.1875rem;
    height: 3rem;
    flex-shrink: 0;
}

.search-result-table-col:nth-child(8) {
    width: 4.1875rem;
    height: 3rem;
    flex-shrink: 0;
}

.detail-button {
    display: flex;
    width: 5.75rem;
    height: 1.375rem;
    overflow: hidden;
    color: var(--primary-primary-400, #0336FF);
    text-overflow: ellipsis;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
    border: none;
    background: inherit;
}

.data-text {
    color: #191919;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.detail-button-icon {
    width: 1.75rem;
    height: 1.75rem;
    background : url("../Assets/chevron-right.png");
}

.pagination {
    margin-left: 58rem;
    margin-top: 1.5rem;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 0.5rem;
  }
  
  .pagination-row {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }
  
  .pagination-column {
    display: flex;
    height: 2rem;
    padding: 0rem 0.5rem;
    justify-content: center;
    align-items: center;
  }
  
  .pagination-column-1 {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 900;
    line-height: 1.125rem; /* 128.571% */
  }
  .pagination-column-2 {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
  }
  
  .pagination-column-3 {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
  }

.icon {
  width: 1.5rem;
  height: 1.5rem;
}

.pagination-left-arrow {
  margin-bottom: 0.25rem;
  border-radius: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  background: url('../Assets/chevron_right.png');
}

.arrow-enabled {
    background-color: #0336FF!important;
}

.arrow-disabled {
    background-color: #C0C3C5!important;
}

.pagination-right-arrow {
  margin-bottom: 0.25rem;
  border-radius: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  background: url('../Assets/chevron_right_white.png');
}

.inactive-icon {
    margin-left: 0.2rem;
    display: flex;
    width: 1.19rem;
    height: 1.2rem;
    background: url(../Assets/image.png);
}

.align-center {
    align-items: center!important;
}

.inactive-icon-flex {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

@media only screen and (min-width: 1600px) {
    .pagination  {
        margin-left: 65rem;
    }
}