.input-container {
    display: flex;
    width: 74rem;
    align-items: flex-start;
    gap: 6.25rem;
}

.title-input:focus{
    outline: none;
}

input::placeholder {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

input::-webkit-input-placeholder {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

input::-moz-placeholder {
    color: var(--gray-scale-neutral-8, #404446);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.input-container-item {
    display: flex;
    width: 48.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex-shrink: 0;
}

.input-container-item-tx {
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.mandatory {
    color: var(--semantic-error-200, #C90000);
    /* Body/Base */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
}

.title-input {
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
    flex: 1 0 0;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-white, #FFF);
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.field-description {
    display: flex;
    padding-top: 30px;
    align-items: flex-start;
    gap: 0.625rem;
    width: 16rem;

}

.field-description-txt {

    margin-bottom: 0;
    color: #000;

    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
    
}

.input-container-heading{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}

.input-characterLimit-tx{
    color: black;
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 200;
    line-height: 1.125rem; /* 128.571% */
}