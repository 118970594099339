.single-dropdown-container {
    display: flex;
    width: 74rem;
    align-items: flex-start;
    gap: 6.25rem;
    overflow: auto;
}

.single-dropdown-input {
    display: flex;
    width: 48.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    flex-shrink: 0;
}
.single-dropdown-input-tx {
    color: var(--gray-scale-black, #000);

    /* Body/Base */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.mandatory {
    color: var(--semantic-error-200, #C90000);
    /* Body/Base */
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
}

.field-description {
    display: flex;
    padding-top: 30px;
    align-items: flex-start;
    gap: 0.625rem;
    width: 16rem;

}

.field-description-txt {

    margin-bottom: 0;
    color: #000;

    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
    
}

.single-dropdown {
    margin-bottom: 0rem;
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-white, #FFF);
}

.single-dropdown select:invalid { 
    color: var(--gray-scale-neutral-5, #A1A6A9);

    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.single-dropdown select:focus {
    outline: none;
}

.single-dropdown select {
    appearance: none;
    background-color: inherit;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    color: var(--gray-scale-black, #000);

    /* Body/Small */
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.single-dropdown select:focus {
    outline: none;
}

.dropdown-style {
    width: 50rem;
}

.custom-arrow {
    display: inline;
    pointer-events: none;
    top: 0rem;
    right: 1rem;
    position: relative;
    width: 1.125rem;
    height: 1.125rem;
    flex-shrink: 0;
    background: url("../Assets/chevron-down.png");
}

.input {
    width: 46rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    overflow-x: auto;
}

.single-dropdown-option {
    width: inherit !important;
    margin-top: 1rem !important;
}

.dropdown-option-container {
    z-index: 999!important;
    position: absolute;
    width: 10rem;
    max-height: 15rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-neutral-3, #E0E1E2);
    background-color: var(--gray-scale-white, #FFF);
    box-shadow: 0px 6px 12px 0px rgba(39, 43, 48, 0.05);
}

.dropdown-each-option {
    display: flex;
    padding: 0.75rem 1rem;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 0;
    color: var(--gray-scale-black, #000);
    font-family: Helvetica Neue;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    border-bottom: 1px solid #E0E1E2;
}

.selected-options {
    min-width: fit-content;
    display: flex;
    padding: 0.25rem 0.5rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    background: var(--primary-100, #BFCCFF);
    pointer-events: none;
}

.selected-options-x {
    pointer-events: all;
}

.input-disable {
    border: none;
    outline: none;
}

.geo-options {
    margin-top: 1rem;
    margin-left: 2.5rem;
    width: 46rem;
}
 
input[type="dropdown"]:focus{
    border: none;
}

.unselect-all {
    position: relative;
    right: 1.5rem;
}

.geo-countries {
    margin-left: 2.5rem;
    width: 46rem;
}

.dropdown-option-container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  .dropdown-option-container::-webkit-scrollbar-thumb {
    background: #eeebebf5;
    border-radius: 8px;
    border: 2.5px solid #fff;
    height: 8rem;
}
  
.dropdown-option-container::-webkit-scrollbar-track {   
    background: #f8f8f8;
    border-radius:2rem ;
    border: 10px solid #fff;
}

.dropdown-option-container::-webkit-scrollbar-track:end {
    background: transparent;
    margin-bottom: 10px; 
}

.dropdown-option-container::-webkit-scrollbar-track:start {
    background: transparent;
    margin-top: 10px;
}