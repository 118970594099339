.loader {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 35rem;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    animation: spin 2s linear infinite;
}

.approve-loading {
    margin-top: 15rem!important;
    margin-left: 45rem!important;
}

@media only screen and (min-width: 1600px) { 
    .approve-loading {
        margin-left: 55rem!important;
    }
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}