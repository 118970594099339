.video-saved-container{
    width: 612px;
    margin-left: 7.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.5rem;
}

.video-saved-item1, .video-saved-item2, .video-saved-inner-flex, .video-saved-text-flex, .brightcove-id-flex {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.video-saved-item1 {
    gap: 2.5rem;
}

.video-saved-item2 {
    gap: 1rem;
}

.video-saved-inner-flex {
    gap: 0.75rem;
}

.video-saved-text-flex {
    gap: 0.5rem;
}

.video-saved-text, .video-saved-desc, .brightcove-id-heading, .brightcove-id, .brightcove-id-copy, .video-saved-item2-desc {
    font-family: Helvetica Neue;
    font-style: normal;
}

.video-saved-text {
    color: var(--gray-scale-black, #000);
    text-align: right;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.875rem; /* 125% */
    letter-spacing: -0.015rem;
}

.video-saved-desc {
    color: var(--gray-scale-neutral-8, #404446);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.375rem; /* 137.5% */
}

.brightcove-id-flex {
    gap: 0.5625rem;
}

.brightcove-id-heading {
    color: var(--gray-scale-black, #000);
    text-align: right;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
}

.brightcove-id-inner-flex {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.brightcove-id-box {
    display: flex;
    width: 16rem;
    padding: 1rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-scale-gray, #B2B7BD);
    background: var(--gray-scale-white, #FFF);
}

.brightcove-id {
    flex: 1 0 0;
    color: var(--gray-scale-neutral-8, #404446);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.brightcove-id-copy {
    color: var(--primary-primary-400, #0336FF);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
}

.video-saved-item2-desc {
    color: var(--gray-scale-neutral-8, #404446);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
}

.video-saved-button {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    background: var(--primary-primary-400, #0336FF);
    color: var(--gray-scale-white, #FFF);
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem; /* 137.5% */
    border: none;
}